import { BdsGrid, BdsLoadingSpinner, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PermissionItem } from './components/PermissionItem';
import { AttendantPermission } from 'constants/Permissions';
import ConfigurationsContainer from '../components/ConfigurationsContainer/ConfigurationsContainer';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { useBotPermissions } from 'hooks/useBotPermissions';
import { useEffect, useState } from 'react';
import { BotPermissions } from 'objects/types/BlipApi';

export const Permissions = () => {
  const { handleConfigurationChange, handleBotPermissions } = useBotPermissions();
  const [botPermissions, setBotPermissions] = useState<BotPermissions>();
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await handleBotPermissions();
      if (permissions) {
        setBotPermissions(permissions);
      }
      setInfoLoaded(true);
    };
    fetchPermissions();
  }, []);

  if (!infoLoaded)
    return (
      <BdsGrid justifyContent="center" alignItems="center" height="100%">
        <BdsLoadingSpinner />
      </BdsGrid>
    );

  return (
    <>
      <SubPageHeader showTestButton />
      <ConfigurationsContainer>
        <BdsGrid direction="column" gap="2">
          <BdsTypo variant="fs-16">Habilite quais recursos todos os seus atendentes terão acesso no dia a dia</BdsTypo>
          <PermissionItem
            permissionTitle="Consultar contatos"
            permissionDescription="Permite consultas a informações de atendimentos anteriores em Conversas"
            onChange={ev =>
              handleConfigurationChange('HistoryEnabled', AttendantPermission.canAccessContactHistory, ev)
            }
            isActive={botPermissions?.historyEnabled || false}
          />
          <PermissionItem
            permissionTitle="Editar contato em Conversas"
            permissionDescription="Permite editar informações básicas dos contatos em Conversas"
            onChange={ev => handleConfigurationChange('CanEditContact', AttendantPermission.canEditContact, ev)}
            isActive={botPermissions?.canEditContact || false}
          />
        </BdsGrid>
      </ConfigurationsContainer>
    </>
  );
};
