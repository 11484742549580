import './style.scss';
import { BdsBanner, BdsButton, BdsGrid, BdsIcon, BdsPaper, BdsTypo, BdsUpload } from 'blip-ds/dist/blip-ds-react';
import { Template } from 'objects/types/MessageTemplate';
import sanitizeHtml from 'sanitize-html';
import { useState } from 'react';
import { BannerVariant } from 'blip-ds/dist/types/components/banner/banner';
import { BdsUploadCustomEvent } from 'blip-ds';
import * as XLSX from 'xlsx';
import { Analytics } from 'infra/adapters';
import { ACTIVE_MESSAGES } from 'constants/ActiveMessages';

export interface ActiveMessagesProViewMessagePreviewProps {
  template: Template | undefined;
  handleMessagePreviewNext: () => void;
  handleMessagePreviewBack: () => void;
}

export const ActiveMessagesProViewMessagePreview = ({
  template,
  handleMessagePreviewNext,
  handleMessagePreviewBack,
}: ActiveMessagesProViewMessagePreviewProps) => {
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [uploadErrorIcon, setUploadErrorIcon] = useState<BannerVariant>();
  const [disableNextButton, setDisableNextButton] = useState(true);

  const FILE_TYPES = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const getVariables = () => {
    const variables = ['telefone'];
    const regExp = /{{([^}]+)}}/g;
    const stringToSearch = template?.body.text ?? '';
    let currentMatch;
    while ((currentMatch = regExp.exec(stringToSearch))) {
      variables.push(currentMatch[1]);
    }
    return variables;
  };

  const getTemplateBody = () => {
    let templateBody = template?.body.text;
    templateBody = templateBody?.replaceAll('\\n', '<br />');
    templateBody = templateBody?.replaceAll('{{', '<b>{{');
    templateBody = templateBody?.replaceAll('}}', '}}</b>');
    templateBody = templateBody
      ?.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, '<b>$1</b>')
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, '<i>$1</i>')
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, '<s>$1</s>')
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, '<u>$1</u>')
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, '<tt>$1</tt>');
    return sanitizeHtml(templateBody ?? '');
  };

  const onUploadChange = (event: BdsUploadCustomEvent<{ value: File[] }>) => {
    const file = event.detail.value[0];
    setUploadErrorMessage('');
    setDisableNextButton(true);
    if (!file) {
      return;
    }
    if (!FILE_TYPES.includes(file.type)) {
      setUploadErrorIcon('error');
      setUploadErrorMessage('Por favor, selecione um arquivo no formato .xls ou .xlsx');
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = event => onUploadFileReaderOnload(event);
    fileReader.readAsArrayBuffer(file);
  };

  const onUploadFileReaderOnload = (event: ProgressEvent<FileReader>) => {
    const arrayBuffer = event.target?.result;

    if (arrayBuffer) {
      Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_UPLOAD_CTT);

      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json<{ [key: string]: string | number }>(sheet);
      let hasError = false;
      const variables = getVariables();

      if (jsonData.length === 0) {
        hasError = true;
        setUploadErrorMessage('O arquivo carregado está em branco ou incompleto. Verifique e tente novamente.');
      } else {
        jsonData.forEach(row => {
          variables.forEach(variable => {
            if (!row[variable]) {
              hasError = true;
              setUploadErrorMessage(
                'As variáveis do arquivo carregado não correspondem ao modelo de mensagem selecionado.',
              );
            }
          });
        });
      }

      if (!hasError) {
        setDisableNextButton(false);
      } else {
        setUploadErrorIcon('warning');
      }
    }
  };

  const onUploadDelete = () => {
    setUploadErrorMessage('');
    setDisableNextButton(true);
  };

  const downloadTemplate = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_DOWNLOAD_XLSX);
    window.open(ACTIVE_MESSAGES.TEMPLATE_LINK);
  };

  return (
    <BdsPaper className="active-messages-pro-view-message-preview">
      <BdsGrid direction="column" padding="4" margin="x-2">
        <BdsTypo variant="fs-20" bold="bold" margin={false}>
          Pre-visualização da mensagem
        </BdsTypo>
        <BdsGrid className="preview-box" direction="row" gap="8" margin="b-4" padding="y-3">
          <BdsGrid className="preview-template" direction="column" padding="2" justifyContent="space-between">
            <BdsTypo variant="fs-14" margin={false}>
              <div dangerouslySetInnerHTML={{ __html: getTemplateBody() }} />
            </BdsTypo>
            {(template?.buttons?.length ?? 0) > 0 && (
              <BdsGrid
                className="template-buttons"
                direction="row"
                margin="t-2"
                padding="t-2"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {template?.buttons.map((button, idx) => {
                  return (
                    <div className="message-button-separator" key={idx}>
                      <div className="message-button">
                        <BdsTypo variant="fs-14" bold="bold" margin={false}>
                          {button.text}
                        </BdsTypo>
                      </div>
                    </div>
                  );
                })}
              </BdsGrid>
            )}
          </BdsGrid>
          <BdsGrid direction="column" padding="3">
            <BdsTypo variant="fs-16" bold="bold" margin={false}>
              Entenda as variáveis de texto
            </BdsTypo>
            <BdsTypo variant="fs-14" margin={false}>
              Os espaços reservados com <b>&#123;&#123;variavel&#125;&#125;</b>
              <br />
              no texto serão preenchidos com os dados
              <br />
              fornecidos no csv. Como por exemplo:
            </BdsTypo>
            <BdsGrid className="example-table" direction="row" margin="t-2">
              <div className="message-preview-table-example-column">
                <div className="message-preview-table-example-column-header">
                  <BdsTypo variant="fs-12">telefone</BdsTypo>
                </div>
                <div className="message-preview-table-example-column-body">
                  <BdsTypo variant="fs-12">+554198...</BdsTypo>
                </div>
              </div>
              <div className="message-preview-table-example-column">
                <div className="message-preview-table-example-column-header">
                  <BdsTypo variant="fs-12">variável 1</BdsTypo>
                </div>
                <div className="message-preview-table-example-column-body">
                  <BdsTypo variant="fs-12">Nome Pessoa</BdsTypo>
                </div>
              </div>
              <div className="message-preview-table-example-column">
                <div className="message-preview-table-example-column-header">
                  <BdsTypo variant="fs-12">variável 2</BdsTypo>
                </div>
                <div className="message-preview-table-example-column-body">
                  <BdsTypo variant="fs-12">Nome Produto</BdsTypo>
                </div>
              </div>
            </BdsGrid>
            <BdsGrid margin="t-2" direction="row" gap="half">
              <BdsIcon theme="outline" size="x-small" name="info" />
              <BdsTypo variant="fs-12" margin={false}>
                Todas as variáveis devem ser preenchidas.
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
        <BdsTypo className="audience-title" variant="fs-20" bold="bold" margin={false}>
          Audiência
        </BdsTypo>
        <BdsTypo className="audience-description" variant="fs-14" margin={false}>
          Carregue contatos para o disparo em massa e faça upload de uma lista com dados
          <br />
          variáveis que serão utilizados na mensagem.
        </BdsTypo>
        <BdsUpload
          className="audience-file-upload"
          data-testid="upload-component"
          titleName="Carregar contatos (arquivo XLSX)"
          accept={'.xlsx, .xls'}
          onBdsUploadChange={onUploadChange}
          onBdsUploadDelete={onUploadDelete}
        />
        {uploadErrorMessage && (
          <BdsBanner
            className="audience-file-upload-error"
            variant={uploadErrorIcon}
            button-close="true"
            context="inside"
          >
            {uploadErrorMessage}
          </BdsBanner>
        )}
        <BdsGrid
          className="audience-file-upload-model-box"
          direction="row"
          alignItems="center"
          gap="half"
          margin="t-2"
          onClick={downloadTemplate}
        >
          <BdsIcon theme="outline" color="#1E6BF1" size="small" name="file-name-xls" />
          <BdsTypo className="audience-file-upload-model-title" variant="fs-14" margin={false}>
            Faça download do modelo
          </BdsTypo>
        </BdsGrid>
        <BdsGrid direction="row" alignItems="center" gap="half" margin="t-3">
          <BdsIcon theme="outline" size="x-small" name="info" />
          <BdsTypo variant="fs-14" margin={false}>
            Variáveis que precisam estar contidas no XLSX:
          </BdsTypo>
        </BdsGrid>
        <BdsGrid padding="t-2" flexWrap="wrap">
          {getVariables().map((variable, idx) => {
            return (
              <div className="variable-separator" key={idx}>
                <div className="variable">
                  <BdsTypo variant="fs-12" bold="bold" margin={false} data-testid={`variable-${idx}`}>
                    {variable}
                  </BdsTypo>
                </div>
              </div>
            );
          })}
        </BdsGrid>
        <BdsGrid direction="row" gap="2" margin="t-6">
          <BdsButton variant="tertiary" onBdsClick={() => handleMessagePreviewBack()}>
            Voltar
          </BdsButton>
          <BdsButton
            variant="primary"
            onBdsClick={() => handleMessagePreviewNext()}
            disabled={disableNextButton}
            data-testid="continue-button"
          >
            Continuar
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};
