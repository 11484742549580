import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsStep,
  BdsStepper,
} from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { useState } from 'react';
import { Template } from 'objects/types/MessageTemplate';
import { AnswerDirectingOptions, Steps } from 'objects/types/ActiveMessages';
import { Analytics } from 'infra/adapters';
import { ActiveMessagesProViewSelectTemplate } from '../ActiveMessagesProViewSelectTemplate/ActiveMessagesProViewSelectTemplate';
import { ActiveMessagesProViewMessagePreview } from '../ActiveMessagesProViewMessagePreview/ActiveMessagesProViewMessagePreview';
import { ActiveMessagesProViewAnswersDirecting } from '../ActiveMessagesProViewAnswersDirecting/ActiveMessagesProViewAnswersDirecting';

export const ActiveMessagesProView = () => {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.SelectTemplate);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [answerDirecting, setAnswerDirecting] = useState<AnswerDirectingOptions>(AnswerDirectingOptions.Human);

  const handleTemplateClick = (template: Template) => {
    setSelectedTemplate(template);
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SELECTED_MODEL);
    setCurrentStep(Steps.MessagePreview);
  };

  const handleMessagePreviewNext = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_NEXT);
    setCurrentStep(Steps.AnswersDirecting);
  };

  const handleMessagePreviewBack = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK);
    setCurrentStep(Steps.SelectTemplate);
  };

  const handleAnswersDirectingNext = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_RESPONSE_TARGETING, {
      targetIdentifier: answerDirecting === AnswerDirectingOptions.Human ? 'attHuman' : 'startFlow',
    });
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_NEXT);
    setCurrentStep(Steps.ConfirmSendMessage);
  };

  const handleAnswersDirectingBack = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK);
    setCurrentStep(Steps.MessagePreview);
  };

  const showCancelConfirmation = () => {
    setOpenCancelAlert(true);
  };

  const handleCancel = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_CANCEL);
    setOpenCancelAlert(false);
    setSelectedTemplate(undefined);
    setCurrentStep(Steps.SelectTemplate);
  };

  const handleAnswerDirectingOptionSelect = (selected: AnswerDirectingOptions) => {
    setAnswerDirecting(selected);
  };

  return (
    <MainLayout>
      <LayoutContainer>
        <BdsGrid direction="column" gap="2" padding="y-4" margin="x-12" height="calc(100vh - 4rem)">
          <BdsStepper>
            <BdsStep active={currentStep === Steps.SelectTemplate} data-testid="select-template">
              Selecionar modelo
            </BdsStep>
            <BdsStep
              active={currentStep === Steps.MessagePreview || currentStep === Steps.AnswersDirecting}
              data-testid="message-preview"
            >
              Configurar disparo
            </BdsStep>
            <BdsStep active={currentStep === Steps.ConfirmSendMessage} data-testid="confirm-send-message">
              Confirmar envio
            </BdsStep>
          </BdsStepper>
          {currentStep === Steps.SelectTemplate && (
            <ActiveMessagesProViewSelectTemplate handleTemplateClick={template => handleTemplateClick(template)} />
          )}
          {currentStep === Steps.MessagePreview && (
            <ActiveMessagesProViewMessagePreview
              template={selectedTemplate}
              handleMessagePreviewNext={handleMessagePreviewNext}
              handleMessagePreviewBack={handleMessagePreviewBack}
            />
          )}
          {currentStep === Steps.AnswersDirecting && (
            <ActiveMessagesProViewAnswersDirecting
              answerDirecting={answerDirecting}
              handleAnswersDirectingNext={handleAnswersDirectingNext}
              handleAnswersDirectingBack={handleAnswersDirectingBack}
              showCancelConfirmation={showCancelConfirmation}
              handleAnswerDirectingOptionSelect={handleAnswerDirectingOptionSelect}
            />
          )}
          {currentStep === Steps.ConfirmSendMessage && <>Resumo do envio</>}
        </BdsGrid>
        <BdsAlert open={openCancelAlert}>
          <BdsAlertHeader variant="warning" icon="warning">
            Deseja cancelar o envio?
          </BdsAlertHeader>
          <BdsAlertBody>
            Ao <b>confirmar</b>, você perderá todo o progresso e precisará preencher novamente as informações.
          </BdsAlertBody>
          <BdsAlertActions>
            <BdsButton variant="secondary" onBdsClick={() => setOpenCancelAlert(false)}>
              Voltar
            </BdsButton>
            <BdsButton variant="secondary" onBdsClick={handleCancel} data-testid="confirm-cancel-button">
              Confirmar
            </BdsButton>
          </BdsAlertActions>
        </BdsAlert>
      </LayoutContainer>
    </MainLayout>
  );
};
