export const DEFAULT_ROUTE = '/';
export const HOME_ROUTE = `${DEFAULT_ROUTE}home`;
export const BOT_ROUTE = `${DEFAULT_ROUTE}bot`;
export const DESK_ROUTE = `${DEFAULT_ROUTE}desk`;
export const CHANNELS_ROUTE = `${DEFAULT_ROUTE}channels`;
export const ONBOARDING_ROUTE = `${DEFAULT_ROUTE}onboarding`;
export const ERROR_ONBOARDING_ROUTE = `${DEFAULT_ROUTE}installation-error`;

export const EXTENSIONS_ROUTE = `${DEFAULT_ROUTE}extensions`;
export const EXTENSIONS_BLIPCHAT_ROUTE = `${EXTENSIONS_ROUTE}/blipchat`;
export const EXTENSIONS_ADDONS_ROUTE = `${EXTENSIONS_ROUTE}/addons`;
export const CONTACTS_ROUTE = `${DEFAULT_ROUTE}contacts`;

export const PLAN_DETAILS_ROUTE = `${DEFAULT_ROUTE}plan-details`;
export const WPP_ACTIVATION = `${CHANNELS_ROUTE}/wpp-activation/`;
export const NUMBER_PREPARATION = `${WPP_ACTIVATION}prepare-number`;
export const PAYWALL_ROUTE = `${WPP_ACTIVATION}paywall`;
export const ACTIVE_MESSAGES = `${DEFAULT_ROUTE}active-messages`;
export const CONFIGURATIONS_ROUTE = '/configurations';
export const META_CONNECTION_ROUTE = `${WPP_ACTIVATION}connect-meta`;
export const SIGNATURE_ROUTE = `${WPP_ACTIVATION}signature`;
export const INTENTION_PLAN_PRO = `${WPP_ACTIVATION}signature/?plan=pro`;
export const CONNECTION_CHOICE_ROUTE = `${WPP_ACTIVATION}connection-choice`;
export const SUCCESSFUL_ACTIVATION_CHOICE_ROUTE = `${WPP_ACTIVATION}successfull-activation-choice`;

export const GREETING_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/greeting`;
export const ATTENDANTS_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/attendants`;
export const MEMBERS_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/members`;
export const PERMISSIONS_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/permissions`;
export const OPENING_HOURS_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/attendance-hours`;
export const ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/attendance-queues`;
export const MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE = `${CONFIGURATIONS_ROUTE}/menu-automatic-response`;
export const INSTAGRAM_ACTIVATION = `${CHANNELS_ROUTE}/instagram`;
export const INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION = `${INSTAGRAM_ACTIVATION}/facebook-connection`;
export const INSTAGRAM_ACTIVATION_BUSINESS_ACCOUNTS = `${INSTAGRAM_ACTIVATION}/business-accounts`;
export const INSTAGRAM_ACTIVATION_ACTIVATE = `${INSTAGRAM_ACTIVATION}/activate`;
export const INSTAGRAM_ACTIVATION_BIND_PAGES = `${INSTAGRAM_ACTIVATION}/bind-pages`;
export const INSTAGRAM_ACTIVATION_BIND_CHECKLIST = `${INSTAGRAM_ACTIVATION}/checklist`;
export const INSTAGRAM_ACTIVATION_MESSAGES_ACCESS = `${INSTAGRAM_ACTIVATION}/messages-access`;
export const INSTAGRAM_ACTIVATION_ERROR_PAGE = `${INSTAGRAM_ACTIVATION}/error`;
