import { Analytics } from '@segment/analytics-node';
import { SegmentKey } from 'configurations/Environment';
import { ANALYTICS } from 'constants/Analytics';
import { AnalyticsConnector } from 'infra/connectors/AnalyticsConnector';
import { IAnalyticsService } from 'objects/interfaces/IAnalyticsService';
import { LocalStorageService } from 'services/LocalStorageService';
import { v1 } from 'uuid';

export class SegmentAdapter extends AnalyticsConnector implements IAnalyticsService {
  protected tracker: Analytics;
  protected defaultProps: any;
  protected integrations: any;
  protected userIdentity: any;
  protected LocalStorageService: any;

  events = { ...ANALYTICS };

  constructor(clientKey: string) {
    super(clientKey);

    this.tracker = this.analyticsClient;
    this.LocalStorageService = LocalStorageService;
    this.SetDefaults();
    this.Identify();
  }

  private SetDefaults() {
    const traits = this.LocalStorageService.Get(ANALYTICS.USER_TRAITS);

    this.defaultProps = {
      packName: 'Blip go!',
      packId: '60',
      botIdentifier: traits?.routerShortName,
      screenResolution: `${screen.width}x${screen.height}`,
      operationalSystem: this.getOperationalSystem(),
      deviceType: this.getDeviceType(),
      tenant: traits?.tenant.id,
      plan: traits?.tenant?.plan?.name,
      merchantName: traits?.clientIdentity,
      browser: navigator.userAgent,
      ownerSO: 'november',
      codeStatus: 'refatorado',
      blipGoPlan: ''
    };

    this.integrations = {
      'Google Analytics': true,
    };
  }

  private getOperationalSystem() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let operationalSystem = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'Windows';
    } else if (/Android/.test(userAgent)) {
      operationalSystem = 'Android';
    } else if (!operationalSystem && /Linux/.test(platform)) {
      operationalSystem = 'Linux';
    }

    return operationalSystem;
  }

  private getDeviceType() {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  public setPlan(plan: string) {
      this.defaultProps.blipGoPlan = plan;
  }

  Identify(userEmail: string | undefined = undefined) {
    this.userIdentity = userEmail;

    if (!userEmail) {
      this.userIdentity = v1().split('-')[0].concat('@anonymous');
    }

    if (this.tracker?.identify) {
      this.tracker.identify({ userId: this.userIdentity });
    }

    return {
      Track: this.Track,
    };
  }

  Track(event: string, properties?: any) {
    const trackData = {
      event,
      userId: this.userIdentity,
      integrations: { ...this.integrations },
      properties: { ...this.defaultProps, ...properties },
    };

    if (this.tracker?.track) {
      this.tracker.track(trackData);
    }
  }
}

export default new SegmentAdapter(SegmentKey);
