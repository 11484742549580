import { BdsGrid, BdsIcon, BdsRadio, BdsRadioGroup, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Controller } from 'react-hook-form';

interface IRadioButtonOnboardingProps {
  control: any;
  name: string;
  placeholder?: string;
  options?: IOptionsRadio[];
  onChangeParam?: (value: string) => void;
  dataTestId?: string;
  optionsDataTestId?: string;
}

interface IOptionsRadio {
  value: string;
  label: string;
}

export const RadioButtonOnboarding = ({
  control,
  name,
  options,
  placeholder,
  dataTestId,
  optionsDataTestId,
  onChangeParam,
}: IRadioButtonOnboardingProps) => {
  const handleValue = (value: any, onChange: (value: any) => void) => {
    onChange(value);
    if (onChangeParam) {
      onChangeParam(value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <BdsRadioGroup
            id={name}
            style={{ display: 'flex', gap: '2rem', padding: '0.5rem' }}
            onBdsRadioGroupChange={(event: any) => {
              handleValue(event.detail.value, onChange);
            }}
            data-testid={dataTestId}
          >
            {options?.map((option, key) => {
              return (
                <BdsGrid
                  key={key}
                  direction="row"
                  gap="1"
                  alignItems="center"
                  style={{
                    height: '3.5rem',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <BdsRadio
                    value={option.value}
                    key={option.value}
                    label={option.label}
                    data-testid={optionsDataTestId}
                  />
                </BdsGrid>
              );
            })}
          </BdsRadioGroup>

          {error && (
            <BdsGrid alignItems="center" gap="1" style={{ margin: '0.2rem 0.15rem', minHeight: '1.25rem' }}>
              <BdsGrid>
                <BdsIcon name="error" size="x-small" aria-label="error" theme="outline" style={{ color: '#e60f0f' }} />
              </BdsGrid>
              <BdsTypo variant="fs-12" style={{ color: '#e60f0f' }}>
                {error ? error.message : null}
              </BdsTypo>
            </BdsGrid>
          )}
        </>
      )}
    />
  );
};
