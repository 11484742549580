import { Resource } from 'objects/types/commands/Resource';

export const RESOURCES = {
  DAYS: {
    SATURDAY: {
      START: 'Saturday_opening_hour',
      END: 'Saturday_closing_hour',
    },
    SUNDAY: {
      START: 'Sunday_opening_hour',
      END: 'Sunday_closing_hour',
    },
    HOLIDAY: {
      START: 'Holiday_opening_hour',
      END: 'Holiday_closing_hour',
    },
  },
  MESSAGES: {
    PRE_QUEUE: 'preQueueMessage',
    FIRST_CONTACT: 'firstContactMessage',
    RETURN: 'returnMessage',
    WORKDAY_WITH_SATURDAY: 'workdayWithSaturday',
    BUTTON_MESSAGE: 'titleCallUS',
  },
  HOLIDAY_ATTENDANCE: 'Holiday_attendance',
  HOLIDAYS: 'Holidays',
  QUEUES: 'teams',
  PRE_SERVICE_QUESTIONS: 'preServiceQuestionList',
  WORK_DAYS: 'Work_days',
  WORK_SCHEDULE: 'workSchedule',
  EMOJI: 'emoji',

  TWENTY_FOUR_HOURS: 'openHour',
  WITHOUT_FAQ: 'semFaq',

  SATISFACTION_SURVEY: 'serviceNote',
};

export const getResourcesOnboarding = (companyName: string) => {
  const resources: Resource[] = [
    {
      name: 'firstContactMessage',
      type: 'text/plain',
      value: `Olá 👋 Eu sou o assistente virtual de ${companyName}, e estou aqui para agilizar seu atendimento.`,
    },
    {
      name: 'returnMessage',
      type: 'text/plain',
      value: `Olá 👋 Eu sou o assistente virtual de ${companyName}, que bom te ver novamente.`,
    },
    {
      name: '1. Nossos produtos/serviços',
      type: 'text/plain',
      value:
        'Nossa empresa é especialista em [insira aqui a especialidade], oferecemos os melhores produtos/serviços da região, como [Produto 1]; [Produto 2], [Produto 3], entre outros.\n\nConheça mais em: [insira aqui o link do portfolio/loja/catálogo/site]',
    },
    {
      name: '2. Quero comprar/contratar',
      type: 'text/plain',
      value:
        'Para comprar/contratar nossos produtos/serviços, acesse o nosso site em [insira o site/loja/página], ou solicite atendimento humano que um dos nossos especialistas responderá você e te auxiliará.',
    },
    {
      name: '3. Onde nos encontrar',
      type: 'text/plain',
      value:
        'Nós estamos localizados no endereço [insira aqui o endereço]. Nosso espaço físico funciona em horário comercial de 08h às 18h de segunda a sexta feira.\n\n📍 [insira aqui o link do google maps]',
    },
    {
      name: '4. Falar no WhatsApp',
      type: 'text/plain',
      value:
        'No WhatsApp você pode falar com o nosso time para um atendimento mais rápido. Acesse o número e nos envie uma mensagem!\n\n📱 wa.me/55[DDD][telefone]',
    },
  ];

  return resources;
};
