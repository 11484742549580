import { BdsCheckbox, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

type VCheckboxProps = {
  name: string;
  control: any;
  label?: string;
  dataTestId?: string;
  link?: string;
  textLink?: string;
};

export const VCheckbox = ({ name, control, label, link, textLink, dataTestId }: VCheckboxProps) => {
  const [checked, setChecked] = useState(false);

  const handleValue = (onChange: (value: boolean) => void) => {
    if (!checked) {
      onChange(true);
    } else {
      onChange(false);
    }

    setChecked(!checked);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <BdsGrid direction="row">
            <BdsCheckbox
              refer={name}
              checked={checked}
              label={label ?? ''}
              name={name}
              onBdsChange={() => handleValue(onChange)}
              data-testid={dataTestId}
            />
            {link && (
              <BdsGrid margin="l-1">
                <BdsTypo variant="fs-14">
                  <a href="https://www.blip.ai/docs/termos-de-uso-blip-go/" target="_blank" rel="noreferrer">
                    {textLink}
                  </a>
                </BdsTypo>
              </BdsGrid>
            )}
          </BdsGrid>
          {error && (
            <BdsGrid alignItems="center" gap="1" style={{ margin: '0.2rem 0.15rem', minHeight: '1.25rem' }}>
              <BdsGrid>
                <BdsIcon name="error" size="x-small" aria-label="error" theme="outline" style={{ color: '#a80b0b' }} />
              </BdsGrid>
              <BdsTypo variant="fs-12" style={{ color: '#a80b0b' }}>
                {error ? error.message : null}
              </BdsTypo>
            </BdsGrid>
          )}
        </>
      )}
    />
  );
};
