import './style.scss';
import { BdsGrid, BdsIcon, BdsRadio, BdsTypo } from 'blip-ds/dist/blip-ds-react';

type IRadioSelectAnswerDirectingProps = {
  title: string;
  description: string;
  icon: string;
  checked: boolean;
  onClick: () => void;
};

export const RadioSelectAnswerDirecting = ({
  title,
  description,
  icon,
  checked,
  onClick,
}: IRadioSelectAnswerDirectingProps) => {
  return (
    <BdsGrid
      className={'radio-select-answer-directing' + (checked ? ' checked' : '')}
      alignItems="center"
      direction="row"
      padding="4"
      onClick={onClick}
      data-testid="radio-select-answer-directing"
    >
      <BdsIcon theme="outline" size="xxx-large" name={icon} />
      <BdsGrid direction="column" margin="l-2">
        <BdsTypo variant="fs-16" bold="extra-bold">
          {title}
        </BdsTypo>
        <BdsTypo className="answer-description" variant="fs-16">
          {description}
        </BdsTypo>
      </BdsGrid>
      <BdsRadio data-testid="radio-button" className="radio-answer" value="" label="" checked={checked} />
    </BdsGrid>
  );
};
