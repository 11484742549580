import { BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import activeMessageGif from 'assets/ActiveMessageDemo.gif';
import { ActiveMessagesCard } from '../ActiveMessagesCard/ActiveMessagesCard';

export const ActiveMessagesFreeView = () => {
  return (
    <MainLayout>
      <LayoutContainer>
        <BdsGrid direction="column" gap="4" lg="6" md="8" style={{ margin: 'auto' }}>
          <BdsGrid direction="row" gap="4" lg="6" md="8" padding="t-6" style={{ width: '100%' }}>
            <ActiveMessagesCard
              icon="message-ballon"
              title="Modelos de mensagem"
              btnLabel="Criar modelo de mensagem"
              description="Crie modelos de mensagem para utilizar em campanhas no WhatsApp ou contatar ativamente seus leads e clientes."
              testId="create-active-message"
            />
            <ActiveMessagesCard
              icon="megaphone"
              title="Campanhas no WhatsApp"
              btnLabel="Enviar mensagens em massa"
              description="Envie mensagens em massa para o WhatsApp dos seus leads e clientes. Dispare até 1000 mensagens em uma única vez."
              testId="send-active-message"
            />
          </BdsGrid>
          <BdsPaper style={{ background: '#3F7DE8', marginBottom: '5rem' }}>
            <BdsGrid gap="10" padding="3">
              <BdsGrid direction="column" gap="3" justifyContent="center">
                <BdsTypo variant="fs-24" margin={false} bold="bold" style={{ color: 'white' }}>
                  Potencialize seu WhatsApp
                </BdsTypo>
                <BdsTypo variant="fs-16" style={{ color: 'white' }}>
                  Envie mensagens em massa no WhatsApp para divulgar ofertas, realizar vendas ou compartilhar
                  informações sobre seu negócio, e fique mais próximo do seu público.
                </BdsTypo>
                <BdsTypo variant="fs-16" style={{ color: 'white' }}>
                  Mais eficiente que lista de transmissão, pois o seu destinatário não precisa ter seu telefone salvo
                  nos contatos.
                </BdsTypo>
              </BdsGrid>
              <img src={activeMessageGif} width={'50%'} data-testid="activeMessageDemoGif" />
            </BdsGrid>
          </BdsPaper>
        </BdsGrid>
      </LayoutContainer>
    </MainLayout>
  );
};
