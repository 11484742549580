export const ONBOARDING_STEPPER = {
  companyData: 0,
  companyObjectives: 1,
  personalizeService: 2,
  finishingInstallation: 3,
};

export const COMPANY_OBJECTIVES = [
  {
    text: 'Disparar mensagens em massa via WhatsApp',
    icon: 'message-received',
  },
  {
    text: 'Ter um número de WhatsApp Oficial',
    icon: 'whatsapp',
  },
  {
    text: 'Ter um robô de atendimento com respostas automáticas',
    icon: 'robot',
  },
  {
    text: 'Organizar conversas e atendimentos',
    icon: 'message-talk',
  },
];
