export const YUP_MESSAGE_VALIDATIONS = {
  MIXED: {
    REQUIRED: 'Este campo é obrigatório',
    ONE_OF: 'Deve ser um dos seguintes valores: ${values}',
    NOT_ONE_OF: 'Não pode ser um dos seguintes valores: ${values}',
    DEFINED: 'Este campo precisa ter um valor definido',
    NOT_TYPE: 'Formato digitado é inválido',
  },
  STRING: {
    LENGTH: 'Deve ter exatamente ${length} caracteres',
    MIN: 'Deve ter pelo menos ${min} caracteres',
    MAX: 'Deve ter no máximo ${max} caracteres',
    EMAIL: 'Formato de e-mail digitado não é válido',
    URL: 'Deve ter um formato de URL válida',
    TRIM: 'Não deve conter espaços no início ou no fim.',
    LOWER_CASE: 'Deve estar em maiúsculo',
    UPPER_CASE: 'Deve estar em minúsculo',
    MATCHES: 'O valor deve corresponder ao padrão: ${regex}',
    UUID: 'Valor digitado não confere a um UUID válido',
  },
  NUMBER: {
    MIN: 'Deve ser no mínimo ${min}',
    MAX: 'Deve ser no máximo ${max}',
    LESS_THAN: 'Deve ser menor que ${less}',
    MORE_THAN: 'Deve ser maior que ${more}',
    POSITIVE: 'Deve ser um número positivo',
    NEGATIVE: 'Deve ser um número negativo',
    INTEGER: 'Deve ser um número inteiro',
  },
  DATE: {
    MIN: 'Deve ser maior que a data ${min}',
    MAX: 'Deve ser menor que a data ${max}',
  },
  ARRAY: {
    MIN: 'Deve ter no mínimo ${min} itens',
    MAX: 'Deve ter no máximo ${max} itens',
    LENGTH: 'Deve conter exatamente ${length} itens',
  },
  OBJECT: {
    NO_UNKNOWN: 'Deve ser passado um valor definido',
  },
};
