import './style.scss';
import {
  BdsGrid,
  BdsLoadingSpinner,
  BdsTable,
  BdsTableBody,
  BdsTableHeader,
  BdsTableRow,
  BdsTableTh,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { IInstallationState } from 'redux/slices/InstallationSlice/interface';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useEffect, useState } from 'react';
import { getMessagesTemplates } from 'services/BlipGoApiService';
import { Template } from 'objects/types/MessageTemplate';
import { createToastError } from 'services/Toats';
import { TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE } from 'constants/ToastMessages';
import { TemplateListItem } from '../TemplateListItem/TemplateListItem';
import { formatLogMessage } from 'utils/LogMessage';
import { Logger } from 'infra/adapters';

export interface IActiveMessagesProViewSelectTemplateProps {
  handleTemplateClick: (template: Template) => void;
}

export const ActiveMessagesProViewSelectTemplate = ({
  handleTemplateClick,
}: IActiveMessagesProViewSelectTemplateProps) => {
  const className = 'ActiveMessagesProViewSelectTemplate';
  const { tenant, router } = useSelector<RootState, IInstallationState>(useInstallation);
  const [messageTemplates, setMessageTemplates] = useState<Template[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    const methodName = 'loadTemplates';
    try {
      setIsLoading(true);
      const res = await getMessagesTemplates(tenant.id, router.shortName);
      setMessageTemplates(res);
    } catch (ex) {
      createToastError(TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(ex, `Failed to get template messages.`), {
        methodName,
        className,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <BdsGrid justifyContent="center" alignItems="center" height="100%">
          <BdsLoadingSpinner />
        </BdsGrid>
      ) : (
        <>
          <BdsTypo variant="fs-20" bold="bold" margin={false}>
            Comece selecionando um modelo da mensagem pré-aprovada
          </BdsTypo>
          <BdsTable className="templates-table">
            <BdsTableHeader className="templates-table-header">
              <BdsTableRow>
                <BdsTableTh>Nome</BdsTableTh>
                <BdsTableTh>Mensagem</BdsTableTh>
                <BdsTableTh></BdsTableTh>
              </BdsTableRow>
            </BdsTableHeader>
            <BdsTableBody>
              {messageTemplates?.map((template, index) => {
                return (
                  <TemplateListItem
                    key={index}
                    id={template.templateId}
                    text={template.body?.text}
                    onTemplateClick={() => handleTemplateClick(template)}
                  />
                );
              })}
            </BdsTableBody>
          </BdsTable>
        </>
      )}
    </>
  );
};
