import { BdsGrid, BdsInput } from 'blip-ds/dist/blip-ds-react';
import { Controller } from 'react-hook-form';

type VInputProps = {
  name: string;
  control: any;
  placeholder: string;
  icon?: string;
  dataTestId?: string;
};

export const VInput = ({ name, control, placeholder, icon, dataTestId }: VInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <BdsGrid>
          <BdsInput
            placeholder={placeholder}
            icon={icon}
            danger={!!error}
            error-message={error ? error.message : null}
            onBdsChange={onChange}
            data-testid={dataTestId}
          />
        </BdsGrid>
      )}
    />
  );
};
