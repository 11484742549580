import { Module } from 'objects/types/Module';
import { SubPage } from 'objects/types/SubPage';
import {
  ACTIVE_MESSAGES,
  CHANNELS_ROUTE,
  CONTACTS_ROUTE,
  DESK_ROUTE,
  EXTENSIONS_ROUTE,
  GREETING_CONFIGURATIONS_ROUTE,
  HOME_ROUTE,
  PLAN_DETAILS_ROUTE,
} from './Routes';
import SubPages from './SubPages';

interface ConfigurationsModule extends Module {
  subPages: SubPage[];
}

export const Modules = {
  home: {
    title: 'Começo',
    icon: 'home',
    tooltip: 'Começo - Conheça tudo que você pode fazer com o Blip Go!',
    route: HOME_ROUTE,
  } as Module,
  desk: {
    title: 'Conversas',
    icon: 'message-talk',
    tooltip: 'Conversas - Aqui, você visualiza apenas as conversas que foram direcionadas ao atendimento humano.',
    route: DESK_ROUTE,
  } as Module,
  contacts: {
    title: 'Contatos',
    icon: 'contact',
    tooltip: 'Veja os contatos e conversas de seu Blip Go!',
    route: CONTACTS_ROUTE,
  },
  activeMessages: {
    title: 'Disparo em massa no WhatsApp',
    icon: 'megaphone',
    tooltip: 'Dispare mensagens em massa no WhatsApp através do Blip Go!',
    route: ACTIVE_MESSAGES,
  } as Module,
  configurations: {
    title: 'Configurações',
    icon: 'settings-general',
    tooltip: 'Configurações - Personalize e configure as automações do Blip Go!',
    route: GREETING_CONFIGURATIONS_ROUTE,
    subPages: SubPages.configurations,
  } as ConfigurationsModule,
  channels: {
    title: 'Canais',
    icon: 'channels',
    tooltip: 'Canais - Conecte os canais sociais mais utilizados pelos usuários, ao seu Blip Go!',
    route: CHANNELS_ROUTE,
  } as Module,
  extensions: {
    title: 'Extensões e Aplicativos',
    icon: 'bag',
    tooltip: 'Extensões e Aplicativos - Incremente funcionalidades ao seu Blip Go!',
    route: EXTENSIONS_ROUTE,
  } as Module,
  planDetails: {
    title: 'Visão geral do plano',
    icon: 'bill',
    tooltip:
      'Visão geral do plano - Acompanhe informações sobre seu plano, como valores, conversas utilizadas, conversas restantes e muitos mais!',
    route: PLAN_DETAILS_ROUTE,
  },
};

export const HelpCenterModule = {
  title: 'Ajuda',
  icon: 'question',
  tooltip: 'Ajuda',
  route: HOME_ROUTE,
} as Module;
