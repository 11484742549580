import { yupResolver } from '@hookform/resolvers/yup';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { CompanyObjectivesContainer } from './styles';

import { VInput } from 'components/Form/VInput';
import { Analytics } from 'infra/adapters';
import { CompanyObjectivesOnboarding } from 'objects/types/OnboardingOptions';
import { companyObjectivesOnboardingSchema } from 'pages/Onboarding/validations/OnboardingValidation';
import { FormProvider, useForm } from 'react-hook-form';
import { COMPANY_OBJECTIVES } from '../../../../constants/SelfOnboard';
import { CheckBoxOnboarding } from '../CheckBoxOnboarding/CheckBoxOnboarding';

interface IProps {
  nextPageEvent: () => void;
  setCompanyObjectives: (data: CompanyObjectivesOnboarding) => void;
}

export const CompanyObjectives = ({ nextPageEvent, setCompanyObjectives }: IProps) => {
  const methods = useForm<CompanyObjectivesOnboarding>({
    resolver: yupResolver(companyObjectivesOnboardingSchema),
    defaultValues: {
      objectives: ['', '', '', ''],
      textOtherObjective: '',
    },
  });

  const { handleSubmit, control, clearErrors } = methods;

  const handleCheckboxChange = () => {
    clearErrors('textOtherObjective');
  };

  const onSubmit = (data: CompanyObjectivesOnboarding) => {
    const obtective = data.objectives.filter(element => element && element.trim());
    const allObjective = data.textOtherObjective !== '' ? [...obtective, data.textOtherObjective] : obtective;
    setCompanyObjectives(data);
    Analytics.Track(Analytics.events.SETUP_INSTALLATION_OBJECTIVES, {
      objectives: allObjective,
    });
    nextPageEvent();
  };

  return (
    <BdsGrid direction="column">
      <CompanyObjectivesContainer>
        <FormProvider {...methods}>
          <BdsGrid direction="column" padding="1">
            <BdsTypo bold="bold" variant="fs-32" tag="h2" lineHeight="simple">
              Fale sobre seus objetivos
            </BdsTypo>
            <BdsTypo bold="regular" variant="fs-16" margin>
              Agora fale um pouco mais sobre como pretende usar Blip Go.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" margin="t-5" padding="1">
            <BdsTypo bold="bold" variant="fs-24" lineHeight="small">
              Qual seu objetivo ao usar Blip Go?
            </BdsTypo>
            <BdsTypo bold="regular" variant="fs-20">
              Você pode selecionar um ou mais de um objetivos.
            </BdsTypo>
          </BdsGrid>
          {COMPANY_OBJECTIVES.map((objective, i) => {
            return (
              <CheckBoxOnboarding
                key={i}
                control={control}
                name={`objectives[${i}]`}
                value={objective.text}
                dataTestId={`objectives-${i}`}
                text={objective.text}
                icon={objective.icon}
                onChangeParam={handleCheckboxChange}
              />
            );
          })}
          <BdsGrid padding="1" direction="column" style={{ width: '100%' }}>
            <VInput
              control={control}
              name="textOtherObjective"
              placeholder="Outro objetivo. Qual?"
              dataTestId="text_objective"
            />
          </BdsGrid>
          <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
            <BdsButton onBdsClick={() => handleSubmit(onSubmit)()} data-testid="company_objectives">
              Continuar
            </BdsButton>
          </BdsGrid>
        </FormProvider>
      </CompanyObjectivesContainer>
    </BdsGrid>
  );
};
