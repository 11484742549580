import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PROFILE } from 'constants/Profiles';
import { IMembersState, MemberInfo } from 'objects/types/Profile';

const InitialState: IMembersState = {
  admins: [],
  agents: [],
};

const membersSlice = createSlice({
  name: 'members',
  initialState: InitialState,
  reducers: {
    addMembers(state, payload) {
      const admins = payload.payload.filter((profile: MemberInfo) => profile.profileName === PROFILE.admin.name);
      const agents = payload.payload.filter((profile: MemberInfo) => profile.profileName === PROFILE.agent.name);
      return {
        ...state,
        admins,
        agents,
      };
    },
    addAdmin(state, { payload }: PayloadAction<MemberInfo>) {
      return {
        ...state,
        admins: [...state.admins, payload],
      };
    },
    addAgent(state, { payload }: PayloadAction<MemberInfo>) {
      return {
        ...state,
        agents: [...state.agents, payload],
      };
    },
    removeMember(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        admins: state.admins.filter(admin => admin.id !== payload),
        agents: state.agents.filter(agent => agent.id !== payload),
      };
    },
  },
});

export default membersSlice.reducer;

export const { addMembers, addAdmin, addAgent, removeMember } = membersSlice.actions;

export const useMembers = (state: { members: IMembersState }) => state.members;
