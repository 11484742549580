import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react';
import FloatingButton from 'components/FloatingButton';
import { GlobalStyles } from 'globalStyles';
import useActiveModuleManager from 'hooks/useActiveModuleManager';
import { Analytics } from 'infra/adapters';
import { useAuth } from 'oidc-react';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AppCuesService } from 'services/AppCues';
import blipChatImage from './../../assets/BlipChatIcon.png';
import useMainRoute from './hooks/useMainRoute';

const MainRoute: React.FC = () => {
  useActiveModuleManager();
  const auth = useAuth();

  const { initHook, isApplicationReady, goTeamWhatsapp, handleDefaultRoute } = useMainRoute();

  const isReadyToRender = auth.userData && isApplicationReady;

  const { email, name } = auth.userData?.profile || {};

  useEffect(() => {
    if (email) {
      initHook();
      Analytics.Identify(auth.userData?.profile?.email);
      AppCuesService.instance.identify(email, {
        name: name,
        email: email,
        createdAt: new Date(),
      });
      setTimeout(() => AppCuesService.instance.start(), 1000);
      Analytics.Track(Analytics.events.OPEN_LITE_PLATAFORM);
    }
  }, [email]);

  useEffect(() => {
    if (isApplicationReady) {
      handleDefaultRoute();
    }
  }, [isApplicationReady]);

  return isReadyToRender ? (
    <>
      <GlobalStyles />
      <Outlet />
      <FloatingButton
        fBtnImage={blipChatImage}
        tooltip={'Solicitar ajuda'}
        onClick={goTeamWhatsapp}
        dataTestId="fbtn-support"
      />
    </>
  ) : (
    <BdsLoadingPage />
  );
};

export default MainRoute;
