export const APPROVAL_ROUTE = 'approval';
export const DIRECTION_LEAD_FIND_BY_INSTALLATION_ID_ROUTE = 'directionlead/installation';
export const DIRECTION_LEAD_FIND_BY_EMAIL = 'directionlead/email';
export const DIRECTION_LEAD = 'directionlead';
export const DOCUSIGN_SAVE_INFO_ROUTE = 'docusign';
export const GET_INFO_BY_INSTALLATION_ID_ROUTE = 'docusign';
export const DELEGATE_ROUTE = 'Delegation';
export const GET_MESSAGES_ROUTE = 'Messages';
export const GET_CONTACTS_ROUTE = 'Contacts';
export const GET_ACTIVE_MESSAGES_ROUTE = 'Messages/Metrics/Type';
export const GET_CAMPAIGNS_QUANTITY_ROUTE = 'Messages/Campaigns/Quantity';
export const BOT_CONFIGURATION_ROUTE = 'Configurations';
export const ATTENDANT_ROUTE = 'Attendance/Attendants';
export const ATTENDANT_PERMISSION_ROUTE = `${ATTENDANT_ROUTE}/Permissions`;
export const GET_MESSAGE_TEMPLATES_ROUTE = 'Messages/Templates';

export const ACTIVATION_CHOICE_ROUTE = `${APPROVAL_ROUTE}/activation`;
