export enum Steps {
  SelectTemplate,
  MessagePreview,
  AnswersDirecting,
  ConfirmSendMessage,
}

export enum AnswerDirectingOptions {
  Human,
  Chatbot,
}
