const ExternalUrls = {
  blipGoHelp: 'https://sites.google.com/take.net/blipgo/central-de-ajuda',
  blipGoAppHelp: 'https://play.google.com/store/apps/details?id=com.desk&hl=pt_BR&gl=US',
  youtubeHomeVideo: 'https://www.youtube.com/embed/Tx-C43gLYNA',
  termsOfUseBlipGo: 'https://www.blip.ai/docs/termos-de-uso-blip-go/',
  whatsappActivationChat: 'https://api.whatsapp.com/send/?phone=553192670115&text=',
  learnMoreYouTubeVideo: 'https://www.youtube.com/watch?v=g_YG_9MihYU',
  whatsAppExperienceCluster: 'https://api.whatsapp.com/send/?phone=553192670115&text=',
  addBlipChatInWebsite:
    'https://help.blip.ai/hc/pt-br/articles/4474398627607-Como-adicionar-um-bot-em-um-site-utilizando-o-Blip-Chat',
  activeMessageHelp: 'https://blipgo.take.net/central-de-ajuda/atendimento-humano/mensagem-ativa/disparo-em-massa',
};
export default ExternalUrls;
