import { AcceptTermsApi } from 'infra/adapters';
import { AcceptTerm } from 'objects/types/AcceptTerm';

export const postAcceptTermsApi = async (data: AcceptTerm) => {
  const acceptTermsApi = new AcceptTermsApi('postAcceptTermsApi');

  try {
    const response = await acceptTermsApi.Post('v1/Accept', { ...data });
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};
