import { BdsGrid, BdsIllustration, BdsLoadingSpinner, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import DesktopSidebar from 'components/DesktopSidebar/DesktopSidebar';
import Header from 'components/Header/Header';
import { PRO_PLAN_DAUS, PRO_PLAN_PRICE } from 'constants/ProPlan';
import moment from 'moment';
import { IDocusignUserInfo, PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useAuth } from 'oidc-react';
import { ContentHorizontalOffset } from 'pages/InstagramActivation/style';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContractInfo } from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { useCustomerService } from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { delegateBot, getActiveMessageMetrics, getCampaignsQuantity, getSignerInfo } from 'services/BlipGoApiService';
import { getInstallationMetrics } from 'services/PackService';
import { MetricCard } from './components/MetricCard/MetricCard';
import { Divider } from './styles';

function PlanDetails() {
  const { isMenuOpen, planType } = useSelector(useProject);
  const { signedContract } = useSelector(useContractInfo);

  function renderPlanDetails(planType: PlanTypeEnum | undefined) {
    switch (planType) {
      case PlanTypeEnum.Pro:
        return <ProUserInfo />;
      case PlanTypeEnum.Basic:
        return <BasicUserInfo />;
      default:
        return <FreeUserInfo />;
    }
  }

  return (
    <>
      <DesktopSidebar />
      <ContentHorizontalOffset $isMenuOpen={isMenuOpen}>
        <Header title="Visão geral do plano" />
        <BdsGrid direction="column" gap="1" alignItems="center" margin="1">
          {signedContract && planType === PlanTypeEnum.Pro && <ProPlanUsage />}
          <BdsGrid margin="x-10" lg="9">
            <BdsPaper>
              <BdsGrid direction="column" padding="4" gap="3">
                <BdsTypo variant="fs-24" tag="h2" bold="bold" margin={false}>
                  Informações do plano
                </BdsTypo>
                <BdsGrid gap="2" alignItems="center">
                  <BdsIllustration style={{ height: '3.5rem' }} type="blip-solid" name="wink" />
                  <BdsTypo variant="fs-14" tag="p">
                    Aqui você terá informações sobre seu plano contratado, como limite de mensagens, equipes e
                    atendentes, bem como os canais disponíveis para sua empresa!
                  </BdsTypo>
                </BdsGrid>
                {renderPlanDetails(planType)}
              </BdsGrid>
            </BdsPaper>
          </BdsGrid>
        </BdsGrid>
      </ContentHorizontalOffset>
    </>
  );
}

function ProPlanUsage() {
  const { installationId } = useSelector(useInstallation);
  const [currentDaus, setCurrentDaus] = useState<string>();
  const [remainingDaus, setRemainingDaus] = useState<string>();
  const [extraDaus, setExtraDaus] = useState<string>();
  const [totalBill, setTotalBill] = useState<string>();
  const [campaignsQuantity, setCampaignsQuantity] = useState<number>();
  const [activeMessagesQuantity, setActiveMessagesQuantity] = useState<number>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { attendants } = useSelector(useCustomerService);
  const auth = useAuth();
  const { tenant, router } = useSelector(useInstallation);

  useEffect(() => {
    async function fetchInstallationInfo() {
      await delegateBot(router.shortName, router.accessKey);
      const installationInfo = await getInstallationMetrics(installationId, auth?.userData?.access_token as string);
      if (installationInfo) {
        setCurrentDaus(installationInfo?.currentMonthDAUsAmount.toString());
        calculateRemainingDaus(installationInfo?.currentMonthDAUsAmount);
        calculateExtraDaus(installationInfo?.currentMonthDAUsAmount);
      }
      await fetchCampaignInfo();
      setIsDataLoaded(true);
    }
    fetchInstallationInfo();
  }, []);

  const fetchCampaignInfo = async () => {
    const startOfMonth = moment().startOf('month').toISOString();
    const endOfMonth = moment().endOf('month').toISOString();
    const activeMessageInfo = await getActiveMessageMetrics(
      tenant?.id,
      router.shortName,
      'M',
      startOfMonth,
      endOfMonth,
    );
    if (activeMessageInfo) {
      setActiveMessagesQuantity(activeMessageInfo?.count);
    }

    const campaignsInfo = await getCampaignsQuantity(tenant?.id, router.shortName);
    if (campaignsInfo) {
      setCampaignsQuantity(campaignsInfo?.currentMonth);
    }
  };

  const calculateRemainingDaus = (currentDaus: number) => {
    const difference = PRO_PLAN_DAUS - currentDaus;
    const remainingDausQuantity = difference <= 0 ? 0 : difference;
    setRemainingDaus(remainingDausQuantity.toString());
  };

  const calculateExtraDaus = (currentDaus: number) => {
    const difference = PRO_PLAN_DAUS - currentDaus;
    const extraDausQuantity = difference <= 0 ? Math.abs(difference) : 0;
    setExtraDaus(extraDausQuantity.toString());
    const totalPrice = PRO_PLAN_PRICE + extraDausQuantity;
    setTotalBill(totalPrice.toString().replace('.', ','));
  };

  if (!isDataLoaded)
    return (
      <BdsPaper style={{ width: '100%' }}>
        <BdsGrid justifyContent="center" padding="2">
          <BdsLoadingSpinner size="small" />
        </BdsGrid>
      </BdsPaper>
    );

  return (
    <BdsGrid lg="9" md="12" data-testId="pro-plan-usage-container">
      <BdsPaper style={{ width: '100%' }}>
        <BdsGrid lg="12" direction="column" padding="4" gap="3">
          <BdsGrid gap="3" flexWrap="wrap" justifyContent="center">
            <MetricCard
              value={currentDaus}
              title="Conversas realizadas"
              tooltipText="Total de conversas iniciadas pela empresa (ativa) ou pelo cliente (receptiva) no período de 0h e 23:59h do mesmo dia."
            />
            <MetricCard
              value={remainingDaus}
              title="Conversas restantes"
              tooltipText="Conversas disponíveis para consumo dentro da franquia do plano contratado."
            />
            <MetricCard
              value={extraDaus}
              title="Conversas extras"
              tooltipText="Conversas consumidas além da franquia do plano contratado."
            />
            <MetricCard
              value={campaignsQuantity}
              title="Campanhas via WhatsApp"
              tooltipText="Campanhas de mensagens ativas em massa enviadas pelo WhatsApp."
            />
            <MetricCard
              value={activeMessagesQuantity}
              title="Mensagens ativas"
              tooltipText="Total de mensagens ativas enviadas pela empresa, que iniciam uma conversa."
            />
            <MetricCard
              value={attendants?.length}
              title="Atendentes"
              tooltipText="Atendentes cadastrados que realizaram ao menos um atendimento no mês corrente."
            />
          </BdsGrid>
          <Divider />
          <BdsGrid gap="3" flexWrap="wrap" justifyContent="center">
            <MetricCard value={'R$ 399,99'} title="Valor do plano" tooltipText="Valor total do plano contratado" />
            <MetricCard
              value={`R$ ${extraDaus},00`}
              title="Valor conversas extras"
              tooltipText="Valor das conversas extras utilizadas no mês corrente."
            />
            <MetricCard
              value={`R$ ${totalBill}`}
              title="Fatura mensal"
              tooltipText="Valor do plano somado ao consumo extra, se houver."
            />
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </BdsGrid>
  );
}

function ProUserInfo() {
  const [proUserInfo, setProUserInfo] = useState<null | IDocusignUserInfo>(null);
  const [signatureDate, setSignatureDate] = useState<string>();
  const { installationId } = useSelector(useInstallation);

  useEffect(() => {
    async function fetchUserInfo() {
      const plans = await getSignerInfo(installationId.toString());
      const proPlanInfo = plans?.find(plan => plan.planType === PlanTypeEnum.Pro);
      if (proPlanInfo) {
        setProUserInfo(proPlanInfo);
        setSignatureDate(moment(proPlanInfo?.updatedAt).format('DD/MM/YYYY'));
      }
    }
    fetchUserInfo();
  }, []);

  if (!proUserInfo)
    return (
      <BdsGrid justifyContent="center">
        <BdsLoadingSpinner size="small" />
      </BdsGrid>
    );

  return (
    <BdsGrid gap="3">
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Dados da empresa e representante
        </BdsTypo>
        <BdsTypo>
          <b>Razão social: </b>
          {proUserInfo.companyName}
        </BdsTypo>
        <BdsTypo>
          <b>CNPJ: </b>
          {proUserInfo.cnpj}
        </BdsTypo>
        <BdsTypo>
          <b>Nome: </b>
          {proUserInfo.fullName}
        </BdsTypo>
        <BdsTypo>
          <b>E-mail de cobrança: </b>
          {proUserInfo.billingEmail ?? proUserInfo.email}
        </BdsTypo>
        <BdsTypo>
          <b>Cargo: </b>
          {proUserInfo.position}
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Resumo do plano: Blip Go! PRO
        </BdsTypo>
        <BdsTypo>
          <b>Canais inclusos: </b>WhatsApp, Instagram, Messenger e Blip Chat
        </BdsTypo>
        <BdsTypo>
          <b>Conversas: </b>450/mês
        </BdsTypo>
        <BdsTypo>
          <b>Celulares conectados: </b>07
        </BdsTypo>
        <BdsTypo>
          <b>Atendentes: </b>07
        </BdsTypo>
        <BdsTypo>
          <b>Equipes de atendimento: </b>05
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2">
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Informações sobre valores e pagamento:
        </BdsTypo>
        <BdsTypo>
          <b>Data de assinatura do contrato: </b>
          {signatureDate}
        </BdsTypo>
        <BdsTypo>
          <b>Valor: </b>R$ 399,99/mês [boleto enviado por e-mail]
        </BdsTypo>
        <BdsTypo>
          <b>Conversas extras: </b>R$ 1,00 [cada]
        </BdsTypo>
        <BdsTypo>
          <b>Atendentes extras: </b>R$ 150,00 [cada]
        </BdsTypo>
        <BdsTypo>
          <b>Equipes de atendimento: </b>05
        </BdsTypo>
        <BdsTypo variant="fs-14" italic>
          *Faturamento até o 5 dia útil do mês, com início da cobrança após a contratação
        </BdsTypo>
        <BdsTypo variant="fs-14" italic>
          *Os extras só serão cobrados se usados durante o mês
        </BdsTypo>
      </BdsGrid>
    </BdsGrid>
  );
}

function BasicUserInfo() {
  const [basicUserInfo, setBasicUserInfo] = useState<null | IDocusignUserInfo>(null);
  const [signatureDate, setSignatureDate] = useState<string>();
  const { installationId } = useSelector(useInstallation);

  useEffect(() => {
    async function fetchUserInfo() {
      const plans = await getSignerInfo(installationId.toString());
      const basicPlanInfo = plans?.find(plan => plan.planType == PlanTypeEnum.Basic);
      if (basicPlanInfo) {
        setBasicUserInfo(basicPlanInfo);
        setSignatureDate(moment(basicPlanInfo?.updatedAt).format('DD/MM/YYYY'));
      }
    }
    fetchUserInfo();
  }, []);

  if (!basicUserInfo)
    return (
      <BdsGrid justifyContent="center">
        <BdsLoadingSpinner size="small" />
      </BdsGrid>
    );

  return (
    <BdsGrid gap="3">
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Dados da empresa e representante
        </BdsTypo>
        <BdsTypo>
          <b>Razão social: </b>
          {basicUserInfo.companyName}
        </BdsTypo>
        <BdsTypo>
          <b>CNPJ: </b>
          {basicUserInfo.cnpj}
        </BdsTypo>
        <BdsTypo>
          <b>Nome: </b>
          {basicUserInfo.fullName}
        </BdsTypo>
        <BdsTypo>
          <b>E-mail de cobrança: </b>
          {basicUserInfo.billingEmail ?? basicUserInfo.email}
        </BdsTypo>
        <BdsTypo>
          <b>Cargo: </b>
          {basicUserInfo.position}
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Resumo do plano: Blip Go! Basic
        </BdsTypo>
        <BdsTypo>
          <b>Canais inclusos: </b>WhatsApp, Instagram, Messenger e Blip Chat
        </BdsTypo>
        <BdsTypo>
          <b>Conversas: </b>50/mês
        </BdsTypo>
        <BdsTypo>
          <b>Celulares conectados: </b>04
        </BdsTypo>
        <BdsTypo>
          <b>Atendentes: </b>04
        </BdsTypo>
        <BdsTypo>
          <b>Equipes de atendimento: </b>01
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2">
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Informações sobre valores e pagamento:
        </BdsTypo>
        <BdsTypo>
          <b>Data de assinatura do contrato: </b>
          {signatureDate}
        </BdsTypo>
        <BdsTypo>
          <b>Valor: </b>grátis até 50 conversas/mês [boleto enviado por e-mail]
        </BdsTypo>
        <BdsTypo>
          <b>Conversas extras: </b>R$ 2,00 [cada]
        </BdsTypo>
        <BdsTypo variant="fs-14" italic>
          *Faturamento até o 5 dia útil do mês, com início da cobrança após a contratação
        </BdsTypo>
        <BdsTypo variant="fs-14" italic>
          *Os extras só serão cobrados se usados durante o mês
        </BdsTypo>
      </BdsGrid>
    </BdsGrid>
  );
}

function FreeUserInfo() {
  const { userData } = useAuth();
  const name = userData?.profile.FullName as string;
  const email = userData?.profile.Email as string;
  return (
    <BdsGrid gap="3">
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Dados da empresa e representante
        </BdsTypo>
        <BdsTypo>
          <b>Nome: </b>
          {name}
        </BdsTypo>
        <BdsTypo>
          <b>E-mail: </b>
          {email}
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2" padding="r-2" style={{ borderRight: '1px solid #E7EDF4' }}>
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Resumo do plano: Blip Go! FREE
        </BdsTypo>
        <BdsTypo>
          <b>Canais inclusos: </b>Instagram, Messenger e Blip Chat
        </BdsTypo>
        <BdsTypo>
          <b>Conversas: </b>200/mês
        </BdsTypo>
        <BdsTypo>
          <b>Atendentes: </b>05
        </BdsTypo>
        <BdsTypo>
          <b>Equipes de atendimento: </b>03
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" gap="2">
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Informações sobre valores e pagamento:
        </BdsTypo>
        <BdsTypo>
          <b>Valor: </b>Gratuito
        </BdsTypo>
      </BdsGrid>
    </BdsGrid>
  );
}

export default PlanDetails;
