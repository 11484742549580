import { WIDTH_COLLAPSE_CLOSE, WIDTH_COLLAPSE_OPEN } from 'constants/Desktop';
import styled from 'styled-components';

interface IProps {
  $isCollapseOpen?: boolean;
  $isModuleActive?: boolean;
}

export const SidebarContainer = styled.div<IProps>`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #141414;
  color: white;
  position: fixed;
  width: ${({ $isCollapseOpen }) => ($isCollapseOpen ? `${WIDTH_COLLAPSE_OPEN};` : `${WIDTH_COLLAPSE_CLOSE};`)};
  padding: ${({ $isCollapseOpen }) => ($isCollapseOpen ? '16px;' : 'var(--space-2, 16px) var(--space-1, 8px);')};
`;

export const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const TopSection = styled.div`
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 82px;
  align-self: stretch;
`;

export const BlipGoLogo = styled.img<IProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4.412px;

  ${({ $isCollapseOpen }) =>
    $isCollapseOpen
      ? 'width: 167.671px;' + 'height: 40px;' + 'padding: 0px 3.415px 0.548px 0px;'
      : 'width: 40px;' + 'height: 77.818px;' + 'padding: 0px 0.3px 3.202px 0.32px;'}
`;

export const CollaspseContainer = styled.div`
  display: contents;
  .tooltip {
    margin: -40px 0px -60px 0px;
  }
`;

export const CollapseItem = styled.div<IProps>`
  width: ${({ $isCollapseOpen }) => ($isCollapseOpen ? '190px;' : '56px;')};
  height: 30px;

  .icon {
    position: absolute;
    color: white;
    right: -5px;
    top: 7px;
    cursor: pointer;
  }
`;

export const CollapseButton = styled.img<IProps>`
  width: 28px;
  height: 38px;
  position: absolute;
  right: -4px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-self: stretch;
`;

export const ItemContainer = styled.div<IProps>`
  width: ${({ $isCollapseOpen }) => ($isCollapseOpen ? '174px;' : 'auto;')};
  height: 44px;

  .icon {
    color: white;
    padding-right: ${({ $isCollapseOpen }) => ($isCollapseOpen ? '6px;' : '')};
  }
`;

export const Item = styled.div<IProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 8px;
  padding: 6px 8px;
  background: ${({ $isModuleActive }) => ($isModuleActive ? 'var(--color-primary, #1E6BF1)' : '')};

  .balloonFontColor {
    color: #454545;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;
`;

export const HelpCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const ProjectVersion = styled.div<IProps>`
  display: flex;

  .typoColor {
    color: white;
  }

  ${({ $isCollapseOpen }) =>
    $isCollapseOpen
      ? 'padding: 0px 12px;' + 'align-items: flex-start;' + 'gap: 10px;' + 'align-self: stretch;'
      : 'width: 51px;' +
        'height: 30px;' +
        'flex-direction: column;' +
        'justify-content: center;' +
        'align-items: center;'}
`;
