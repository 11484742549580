import { BdsButton, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ProPlanModal } from 'components/ProPlanModal/ProPlanModal';
import { useRef } from 'react';

interface IActiveMessagesCard {
  icon: string;
  title: string;
  description: string;
  btnLabel: string;
  testId: string;
}

export const ActiveMessagesCard = ({ icon, title, testId, description, btnLabel }: IActiveMessagesCard) => {
  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  const handleClick = () => {
    if (modalRef?.current) {
      modalRef.current.toggle?.();
    }
  };

  return (
    <BdsPaper style={{ background: '#FFF' }}>
      <BdsGrid
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding="3"
        gap="2"
        style={{ width: '100%' }}
      >
        <BdsGrid alignItems="center" gap="2">
          <BdsIcon name={icon} size="brand"></BdsIcon>
          <BdsTypo variant="fs-20" margin={false} bold="bold">
            {title}
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-14">{description}</BdsTypo>
        <BdsButton onBdsClick={handleClick} data-testid={testId}>
          {btnLabel}
        </BdsButton>
      </BdsGrid>
      <ProPlanModal modalRef={modalRef} activeMessageOrigin={true} />
    </BdsPaper>
  );
};
