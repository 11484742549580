import './style.scss';
import { BdsButton, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { RadioSelectAnswerDirecting } from '../RadioSelectAnswerDirecting/RadioSelectAnswerDirecting';
import { AnswerDirectingOptions } from 'objects/types/ActiveMessages';

export interface ActiveMessagesProViewAnswersDirectingProps {
  answerDirecting: AnswerDirectingOptions;
  handleAnswersDirectingNext: () => void;
  handleAnswersDirectingBack: () => void;
  showCancelConfirmation: () => void;
  handleAnswerDirectingOptionSelect: (selected: AnswerDirectingOptions) => void;
}

export const ActiveMessagesProViewAnswersDirecting = ({
  answerDirecting,
  handleAnswersDirectingNext,
  handleAnswersDirectingBack,
  showCancelConfirmation,
  handleAnswerDirectingOptionSelect,
}: ActiveMessagesProViewAnswersDirectingProps) => {
  return (
    <BdsPaper className="active-messages-pro-view-answers-directing">
      <BdsGrid direction="column" padding="4" margin="x-2">
        <BdsTypo variant="fs-20" bold="bold" margin={false}>
          Direcionamento de respostas
        </BdsTypo>
        <BdsTypo className="description" variant="fs-14" margin={false}>
          Defina para onde o contato será direcionado caso responda a mensagem.
        </BdsTypo>
        <BdsGrid direction="row" margin="t-4" gap="2">
          <RadioSelectAnswerDirecting
            icon="agent"
            title="Atendimento humano"
            description="Transferido diretamente para a fila de espera dos atendentes."
            checked={answerDirecting === AnswerDirectingOptions.Human}
            onClick={() => handleAnswerDirectingOptionSelect(AnswerDirectingOptions.Human)}
          />
          <RadioSelectAnswerDirecting
            icon="robot"
            title="Início do fluxo"
            description="A conversa inicia do zero, sendo atendido pelo seu chatbot."
            checked={answerDirecting === AnswerDirectingOptions.Chatbot}
            onClick={() => handleAnswerDirectingOptionSelect(AnswerDirectingOptions.Chatbot)}
          />
        </BdsGrid>
        <BdsGrid direction="row" gap="2" margin="t-6">
          <BdsButton variant="tertiary" onBdsClick={() => handleAnswersDirectingBack()}>
            Voltar
          </BdsButton>
          <BdsButton variant="primary" onBdsClick={() => handleAnswersDirectingNext()} data-testid="continue-button">
            Continuar
          </BdsButton>
          <BdsGrid className="cancel-button-box" direction="row" justifyContent="flex-end">
            <BdsButton
              variant="text"
              color="content"
              onBdsClick={() => showCancelConfirmation()}
              data-testid="cancel-button"
            >
              Cancelar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};
