import { BdsSelect, BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { Controller } from 'react-hook-form';

interface IVSelectProps {
  control: any;
  name: string;
  placeholder?: string;
  icon?: string;
  options?: any[];
  dataTestId?: string;
  optionsDataTestId?: string;
}

export const VSelect = ({
  control,
  name,
  options,
  placeholder,
  icon,
  dataTestId,
  optionsDataTestId,
}: IVSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <BdsSelect
          onBdsChange={onChange}
          danger={!!error}
          data-testid={dataTestId}
          placeholder={placeholder}
          icon={icon}
          error-message={error ? error.message : null}
        >
          {options?.map(option => {
            return (
              <BdsSelectOption value={option.value} key={option.value} data-testid={optionsDataTestId}>
                {option.label}
              </BdsSelectOption>
            );
          })}
        </BdsSelect>
      )}
    />
  );
};
