import { BdsCheckbox, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { BorderColorWhenSelected, ClickableCheckBox } from 'pages/Onboarding/styles';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

type CheckBoxOnboardingProps = {
  name: string;
  control: any;
  label?: string;
  value: string;
  icon: string;
  text: string;
  dataTestId?: string;
  onChangeParam?: () => void;
};

export const CheckBoxOnboarding = ({
  name,
  control,
  label,
  value,
  dataTestId,
  icon,
  text,
  onChangeParam,
}: CheckBoxOnboardingProps) => {
  const [checked, setChecked] = useState(false);

  const handleValue = (onChange: (value: string) => void) => {
    if (!checked) {
      onChange(value);
    } else {
      onChange('');
    }

    if (onChangeParam) {
      onChangeParam();
    }

    setChecked(!checked);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <ClickableCheckBox>
          <BorderColorWhenSelected $isChecked={checked}>
            <BdsGrid direction="row" margin="t-1" padding="1" justifyContent="space-between">
              <BdsGrid direction="row" gap="1">
                <BdsIcon theme="outline" color="#1E6BF1" size="medium" name={icon} />
                <BdsTypo variant="fs-14" bold="semi-bold">
                  {text}
                </BdsTypo>
              </BdsGrid>
              <BdsCheckbox
                refer={name}
                checked={checked}
                label={label ?? ''}
                name={name}
                onBdsChange={() => handleValue(onChange)}
                data-testid={dataTestId}
              />
            </BdsGrid>
          </BorderColorWhenSelected>{' '}
        </ClickableCheckBox>
      )}
    />
  );
};
