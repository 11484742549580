import {
  TOAST_FAILURE,
  TOAST_FAILURE_MESSAGE,
  TOAST_GET_FAILURE_MESSAGE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE,
} from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import Attendant from 'objects/types/Attendant';
import { useSelector } from 'react-redux';
import { useCustomerService } from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { getBotPermissions, setAttendantsPermissions, setBotPermissions } from 'services/BlipGoApiService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';

export const useBotPermissions = () => {
  const className = 'useBotPermissions';
  const { tenant, desk } = useSelector(useInstallation);
  const { attendants } = useSelector(useCustomerService);

  const handleConfigurationChange = async (configuration: string, attendantPermission: string, status: boolean) => {
    const methodName = 'handleConfigurationChange';
    try {
      await setBotPermissions(tenant?.id, desk.shortName, configuration, status);
      const newAttendants = attendants.map((agent: Attendant) => agent.email);
      await handleAttendantPermissionChange(attendantPermission, status, newAttendants);
      Analytics.Track(Analytics.events.TOGGLE_BOT_PERMISSION, {
        status: 'sucesso',
        type: configuration,
        value: status,
      });
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);
    } catch (ex) {
      Analytics.Track(Analytics.events.TOGGLE_BOT_PERMISSION, {
        status: 'falha',
        type: configuration,
        value: status,
      });
      Logger.Error(formatLogMessage(ex, `Failed to change bot configurations (${configuration}).`), {
        methodName,
        className,
      });
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
    }
  };

  const handleAttendantPermissionChange = async (
    attendantPermission: string,
    status: boolean,
    newAttendants: string[],
  ) => {
    const methodName = 'handleAttendantPermissionChange';
    try {
      await setAttendantsPermissions(tenant?.id, desk.shortName, attendantPermission, status, newAttendants);
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, `Failed to change attendant bot permission (${attendantPermission}).`), {
        methodName,
        className,
      });
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
    }
  };

  const handleBotPermissions = async () => {
    const methodName = 'handleBotPermissions';
    try {
      const permissions = await getBotPermissions(tenant.id, desk.shortName);
      return permissions;
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, 'Failed to retrieve bot permissions.'), {
        methodName,
        className,
      });
      createToastError(TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE);
    }
  };

  return {
    handleBotPermissions,
    handleConfigurationChange,
    handleAttendantPermissionChange,
  };
};
